import { useRequestEvent } from '#imports'

import { invariant } from '@backmarket/utils/function/invariant'

import type { HttpFetch, TypeUnsafeHttpFetch } from '../utils/createHttpFetch'
import { getRequestHttpFetch } from '../utils/getRequestHttpFetch'

/**
 * Retrieve the current `$httpFetch` instance automatically, based on the
 * surrounding context. On the server, the instance will be retrieved from
 * the H3 event. On the client, the instance will be retrieved from the
 * global `window` object.
 */
export function useHttpFetch(): HttpFetch {
  const event = useRequestEvent()

  if (event) {
    return getRequestHttpFetch(event)
  }

  invariant(window.$httpFetch, 'window.$httpFetch is missing')

  return window.$httpFetch
}
/**
 * @deprecated please use the type-safe useHttpFetch instead
 * @function useTypeUnsafeHttpFetch is a temporary function to allow a smooth migration
 * to the type-safe useHttpFetch
 * TODO: remove function once migration to type-safe useHttpFetch has been completed
 */
export function useTypeUnsafeHttpFetch(): TypeUnsafeHttpFetch {
  const event = useRequestEvent()

  if (event) {
    return getRequestHttpFetch(event)
  }

  invariant(window.$httpFetch, 'window.$httpFetch is missing')

  return window.$httpFetch as TypeUnsafeHttpFetch
}
