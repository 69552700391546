import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'

import { COOKIES } from '../constants'
import type { DataLayerEvent, GoogleTagManagerConfig } from '../types'

const buffer: DataLayerEvent[] = []
let isCollectingEvents = false

export function pushEventToDataLayer(event: DataLayerEvent) {
  if (!process.client) return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Since should be runnable without 'dom' lib
  if (window.dataLayer) {
    if (isCollectingEvents) {
      // Google Tag Manager mutates the event provided to add a parameter: gtm.uniqueEventId
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Since should be runnable without 'dom' lib
      window.dataLayer.push(event)
    } else {
      buffer.push(event)
    }
  }
}

/**
 *
 * @param reason - The gdpr event that triggered the start of the collection
 */
export function startCollectingEvents(reason?: DataLayerEvent) {
  isCollectingEvents = true

  if (reason) {
    pushEventToDataLayer(reason)
  }

  while (buffer.length) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    pushEventToDataLayer(buffer.shift()!)
  }
}

/**
 * For testing purposes. Resets to not collecting events
 */
export function stopCollectingEvents() {
  isCollectingEvents = false
}

export function getStringifiedConfig(config: GoogleTagManagerConfig) {
  return `auth=${config.auth}&id=${config.id}&preview=${config.preview}`
}

export function getGoogleTagManagerScript(config: GoogleTagManagerConfig) {
  const stringifiedConfig = getStringifiedConfig(config)

  return `
    <!-- Google Tag Manager -->
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?'+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${stringifiedConfig}');</script>
    <!-- End Google Tag Manager -->
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?${stringifiedConfig}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
    <script>
      window.dataLayer = window.dataLayer || []
      // Some pushes to dataLayer store the HTML element that triggered it.
      // By doing so, it creates a reference to existing HTML in the Javascript.
      // Browser when garbage collecting the memory, looks for the referenced HTML in the Javascript
      // and keeps all the referenced HTML in the memory.
      // The more pushes to dataLayer are made, the more you will store HTML in memory that will never be cleared.
      // To prevent this all the HTML elements pushed to the dataLayer has to be cloned before so it's not the same reference.
      // For more informations: https://www.voorhoede.nl/en/blog/your-website-probably-has-a-memory-leak/
      window.dataLayer.push = function(event) {
        if (event['gtm.element']) {
          event['gtm.element'] = event['gtm.element'].cloneNode(true)
        }
        return Array.prototype.push.apply(this, arguments)
      }
    </script>`
}

type InitGTMParameters = {
  isOptInCookieNeeded: boolean
}

export function initGoogleTagManager({
  isOptInCookieNeeded,
}: InitGTMParameters) {
  const optIn = isOptInCookieNeeded
    ? Object.values(COOKIES).every((cookie) =>
        Boolean(getBrowserCookie(cookie)),
      )
    : true

  if (optIn) {
    startCollectingEvents()
  }
}

export function setOptIn(optIn: boolean) {
  if (optIn) {
    startCollectingEvents()
  }
}
